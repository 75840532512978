@import '../../../node_modules/bootstrap/scss/functions';
@import '../../../node_modules/bootstrap/scss/variables';
@import '../../../node_modules/bootstrap/scss/mixins';

.Question {
  text-align: left;

  &-card {
    border-radius: 3px;
    background-color: var(--question-bg-color) !important;
    border-color: var(--question-border-color);
    border-width: var(--question-border-width);
    border-style: solid;
    padding-left: var(--question-card-horizontal-padding) !important;
    padding-right: var(--question-card-horizontal-padding) !important;
    padding-top: var(--question-card-vertical-padding) !important;
    padding-bottom: var(--question-card-vertical-padding) !important;
  }

  &-title {
    font-weight: bold;
    font-size: var(--question-title-font-size-desktop);
    line-height: var(-question-title-line-height-desktop);
    color: var(--question-title-text-color);
    font-weight: var(--question-title-font-weight);

    @media all and (max-width: map-get($grid-breakpoints, md)) {
      font-size: var(--question-title-font-size-mobile);
      line-height: var(-question-title-line-height-mobile);
    }
  }

  &-subtitle {
    font-size: var(--question-subtitle-font-size-desktop);
    color: var(--question-subtitle-text-color);

    @media all and (max-width: map-get($grid-breakpoints, md)) {
      font-size: var(--question-subtitle-font-size-mobile);
    }
  }

  &-description {
    font-size: 1rem;
    color: var(--question-description-text-color);
  }

  &-options {
    border-top: 1px solid var(--question-options-bordertop-color);

    &__option {
      margin-bottom: var(--option-margin-bottom) !important;
    }

    input {
      display: inline-block;
      line-height: calc(1rem);
      font-size: calc(1rem);

      &[type='checkbox'] {
        border-radius: 50%;
      }
    }

    input:checked + label {
      color: var(--option-title-text-color-selected);
      background-color: var(--option-title-background-color-selected);
    }

    label {
      color: var(--option-title-text-color);
      background-color: var(--option-title-background-color);
      display: inline-block;
      vertical-align: top;
      line-height: var(--option-title-lie-height-desktop);
      font-size: var(--option-title-font-size-desktop);

      @media all and (max-width: map-get($grid-breakpoints, md)) {
        // vertical-align: baseline;
        line-height: var(--option-title-lie-height-mobile);
        font-size: var(--option-title-font-size-mobile);
      }
    }

    &.with-circle-options {
      display: flex;
      flex-wrap: wrap;
      max-width: 800px;
      align-items: center;
      justify-content: space-evenly;
      margin-top: 0px !important;
      margin-bottom: 2px !important;
    }
  }
}
