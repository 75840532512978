.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: var(--wrapper-bg-color);
  // background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  // justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  padding-bottom: var(--survey-padding-bottom);
}

.App-link {
  color: #61dafb;
}

.App-spinner-container {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background: var(--wrapper-bg-color);
}

.App-slot-select {
  text-align: center;

  .ant-form-item-label {
    display: flex;
    justify-content: center;
  }

  label > span {
    color: var(--survey-subtitle-text-color) !important;
  }
}

.Madai {
  &-tr-enter {
    opacity: 0;
  }

  &-tr-enter-active {
    opacity: 1;
    transition: opacity 200ms;
  }

  &-tr-exit {
    opacity: 1;
  }

  &-tr-exit-active {
    opacity: 0;
    transition: opacity 200ms;
  }

  &-submit-button {
    color: var(--submit-button-text-color) !important;
    background-color: var(--submit-button-bg-color) !important;
    border-color: var(--submit-button-border-color) !important;
    border-width: var(--submit-button-border-width) !important;
    padding-top: var(--submit-button-padding-vertical) !important;
    padding-bottom: var(--submit-button-padding-vertical) !important;
    padding-right: var(--submit-button-padding-horizontal) !important;
    padding-left: var(--submit-button-padding-horizontal) !important;
    border-radius: var(--submit-button-border-radius) !important;

    &__icon {
      display: var(--submit-button-icon-display);
      margin-left: 15px;
    }
  }

  &-submit-button-fixed-wrapper {
    left: 0;
    position: fixed;
    bottom: 0px;
    padding: 15px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
