.notice {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  &__content {
    padding: 1rem;
    color: var(--survey-title-text-color);
  }
}
