.question-slider-element {
  margin-top: 22px;

  .ant-slider-handle::after {
    transition: none;
  }

  &__tooltip {
    background-color: transparent;
  }

  &__image {
    height: 28px;
    width: 30px;
    pointer-events: none;
  }

  .ant-slider-track {
    display: none;
  }

  .ant-slider-rail {
    background: rgb(255, 255, 255);
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 1) 0%,
      rgba(194, 194, 194, 1) 20%,
      rgba(49, 49, 49, 1) 100%,
      rgba(0, 0, 0, 0) 100%
    );
  }
}

.ant-tooltip.question-slider-element__tooltip {
  .ant-tooltip-arrow::before {
    background: transparent;
  }

  .ant-tooltip-arrow {
    transform: translateX(-50%) translateY(100%) rotate(0deg) !important;
  }

  .ant-tooltip-inner {
    background: transparent;
    color: black;
    box-shadow: none;
  }
}
