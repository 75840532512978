@import '../../../node_modules/bootstrap/scss/functions';
@import '../../../node_modules/bootstrap/scss/variables';

.Question-options__option.is-circle {
  input[type='radio'],
  input[type='checkbox'] {
    display: none;
  }

  padding: 0;

  label {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    cursor: pointer;
  }
}

.isIcon {
  &.form-check {
    position: relative;
    display: inline-block;
    width: 26px;
    padding: 0;
    text-align: center;

    @media all and (min-width: map-get($grid-breakpoints, lg)) {
      width: 50px;
      min-height: 50px;
    }

    &.pale {
      opacity: 0.4;
    }

    input[type='checkbox'] {
      position: absolute;
      left: -100vw;
    }

    input[type='checkbox'] + label {
      display: block;
      left: 0;
      position: absolute;
      content: '' !important;
    }

    input[type='checkbox'] + label::before {
      content: '';
      display: block;
      background-color: var(--question-bg-color) !important;
      width: 20px;
      height: 40px;
      @media all and (min-width: map-get($grid-breakpoints, lg)) {
        width: 33px;
        height: 66px;
      }
      transform: scale(1);
      transition: 120ms transform ease-in-out;
      box-shadow: inset 1em 1em var(--form-control-color);
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
    }
    input[type='radio'] {
      position: absolute;
      left: -100vw;
    }

    input[type='radio'] + label {
      display: block;
      left: 0;
      position: absolute;
      content: '' !important;
    }

    input[type='radio'] + label::before {
      content: '';
      display: block;
      background-color: var(--question-bg-color) !important;
      width: 20px;
      height: 40px;
      @media all and (min-width: map-get($grid-breakpoints, lg)) {
        width: 33px;
        height: 66px;
      }
      transform: scale(1);
      transition: 120ms transform ease-in-out;
      box-shadow: inset 1em 1em var(--form-control-color);
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
    }
  }

  @for $i from 0 through 10 {
    &.isIcon-#{$i} {
      input[type='checkbox'] + label::before {
        background-image: url('/img/icons/' + $i + '.jpg');
      }
      input[type='radio'] + label::before {
        background-image: url('/img/icons/' + $i + '.jpg');
      }
    }
  }
}
