.Survey {
  // Required
  @import '../../../node_modules/bootstrap/scss/functions';
  @import '../../../node_modules/bootstrap/scss/variables';
  @import '../../../node_modules/bootstrap/scss/mixins';

  width: 100%;

  &-card {
    background-color: var(--survey-bg-color) !important;
    // content: map-get($grid-breakpoints, lg);

    &--titles {
      margin-top: var(--survey-titles-margin-top);
      margin-bottom: var(--survey-titles-margin-bottom);
    }
  }

  &-logo {
    text-align: center;

    &__top {
      background-color: var(--logo-container-background-color);
    }

    &__bottom {
      margin-top: 0.5rem;
    }

    img {
      display: inline-block;
      width: var(--survey-logo-width-desktop);
      max-width: 100%;

      @media all and (max-width: map-get($grid-breakpoints, md)) {
        width: var(--survey-logo-width-mobile);
      }
    }
  }

  &-title {
    font-weight: bold;
    font-size: var(--survey-title-font-size-desktop);
    color: var(--survey-title-text-color);
    display: var(--survey-title-display);
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 0.5rem;

    @media all and (max-width: map-get($grid-breakpoints, md)) {
      font-size: var(--survey-title-font-size-mobile);
    }
  }

  &-subtitle {
    font-size: var(--survey-subtitle-font-size-desktop);
    font-weight: var(--survey-subtitle-font-weight);
    font-style: var(--survey-subtitle-font-style);
    color: var(--survey-subtitle-text-color);

    @media all and (max-width: map-get($grid-breakpoints, md)) {
      font-size: var(--survey-subtitle-font-size-mobile);
    }
  }

  &-description-legend {
    font-size: var(--survey-subtitle-font-size-desktop);
    font-weight: var(--survey-subtitle-font-weight);
    font-style: var(--survey-subtitle-font-style);
    color: var(--survey-subtitle-text-color);

    @media all and (max-width: map-get($grid-breakpoints, md)) {
      font-size: var(--survey-subtitle-font-size-mobile);
    }
  }

  &-answered-notice {
    padding-top: 20px;
    color: var(--survey-subtitle-text-color);
  }

  &-single-slot-title {
    font-size: 16px;
    color: var(--survey-subtitle-text-color);
  }

  &-required-message-helper {
    text-align: left;
    padding-left: var(--question-card-horizontal-padding);
    color: var(--survey-title-text-color);
  }
}
